(function () {
  const mainHeader = document.querySelector('.main-header');
  const nav = document.querySelector('.header-nav');
  const dropdown = document.querySelector('.main-header__dropdown-container');
  const burgerButton = document.querySelector('.burger-button');
  const burgerButtonContainer = document.querySelector('.header-burger');
  const subLinks = nav?.querySelectorAll('.sub-menu-parent .caret-image');
  const subMenus = nav?.querySelectorAll('.sub-menu');

  function setDropdownHeight() {
    if (dropdown) {
      dropdown.style = '';
      const height = dropdown.offsetHeight;
      dropdown.style = 'max-height: ' + height + 'px; overflow-y: auto;';
    }
  }

  subLinks?.forEach((item, i) => {
    item.addEventListener('click', () => {
      if (document.body.clientWidth < 1200) {
        setDropdownHeight();
        if (subMenus[i].style.display === 'block') {
          item.style.transform = '';
          subMenus[i].style.display = 'none';
        } else {
          subMenus[i].style.display = 'block';
          item.style.transform = 'rotate(180deg)';
        }
      }
    });
  });

  $("a[href='/register']").attr('href', `/register${window.location.search}`);

  burgerButton?.addEventListener('click', function () {
    if (burgerButton.getAttribute('aria-expanded') === 'true') {
      $('body').css('overflow', 'visible');
      $('#usercom-widget').css('display', 'block');
      burgerButton.setAttribute('aria-expanded', 'false');
      burgerButton.setAttribute('aria-label', 'Open menu');
      burgerButtonContainer.classList.remove('header-burger--opened');
      burgerButton.classList.remove('burger-button--opened');
      dropdown.classList.remove('main-header__dropdown-container--opened');
      nav.classList.remove('active');
      dropdown.style = '';
      mainHeader.classList.remove('main-header--opened');
      return;
    } else {
      $('body').css('overflow', 'hidden');
      $('#usercom-widget').css('display', 'none');
      burgerButton.setAttribute('aria-expanded', 'true');
      burgerButton.setAttribute('aria-label', 'Close menu');
      burgerButtonContainer.classList.add('header-burger--opened');
      burgerButton.classList.add('burger-button--opened');
      dropdown.classList.add('main-header__dropdown-container--opened');
      nav.classList.add('active');
      setDropdownHeight();
      mainHeader.classList.add('main-header--opened');
      return;
    }
  });

  window.addEventListener('resize', function () {
    if (
      dropdown?.classList.contains('main-header__dropdown-container--opened')
    ) {
      setDropdownHeight();
    }

    if (document.body.clientWidth >= 1200 && burgerButton) {
      // remove active class if screen becomes bigger (so we don't need to override everything in media query css)
      burgerButton.setAttribute('aria-expanded', 'false');
      burgerButton.setAttribute('aria-label', 'Open menu');
      burgerButtonContainer.classList.remove('header-burger--opened');
      burgerButton.classList.remove('burger-button--opened');
      dropdown.classList.remove('main-header__dropdown-container--opened');
      mainHeader.classList.remove('main-header--opened');
      nav.classList.remove('active');
      dropdown.style = '';
      subMenus?.forEach((sub) => {
        sub.style = '';
      });

      subLinks?.forEach((link) => {
        link.style = '';
      });
    }
  });

  const selects = document.getElementsByTagName('select');
  for (let select of selects) {
    select.addEventListener('change', function () {
      // if (this.value) {
      this.classList.add('selected');
      // } else {
      //   this.classList.remove('selected');
      // }
    });
  }
})();
